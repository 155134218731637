import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import actions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import moment from "moment";
import { ProductLogo } from "../../../_metronic/layout/components/productLogo";
import ConfirmModal from "../modals/ConfirmModal";
import OptInsModal from "./OptInsModal";
import { getSearchRegExp } from "../../helpers";
import { checkLimits } from "../../plans";
import Lottie from "react-lottie";
import animationData from "../../layout/lottie/empty-keywords.json";

function ManageSMSCodes({ user, products, dispatch, fulfillUser }) {
  let [loading, setLoading] = useState(true),
    [list, setList] = useState([]),
    [selected, setSelected] = useState([]),
    [showConfirmDelete, setShowConfirmDelete] = useState(false),
    [search, setSearch] = useState(null),
    [showOptInsModal, setShowOptInsModal] = useState(null),
    history = useHistory();

  let loadList = async () => {
    setLoading(true);
    let res = await api.sms.list();
    if(!res || !res.success)
      return;
    setList(res.data);
    setLoading(false);
  };

  let getEffectiveStatus = item => {
    if(user && (user.noPlan || !user.planFeatures.smsAccess)) return "unpublished";
    if(item.status == "scheduled" && item.startDate && moment(item.startDate).toDate() <= new Date)
      return "published";
    return item.status;
  };

  let statusLabels = {
    "published": "Published",
    "unpublished": "Unpublished",
    "scheduled": "Scheduled"
  };

  let statusColors = {
    "published": "green-badge",
    "unpublished": "red-badge",
    "scheduled": "yellow-badge"
  };

  let selectedOnChange = ev => {
    let items = selected.filter(i => i != ev.target.value);
    if(ev.target.checked)
      items.push(ev.target.value);
    setSelected(items);
  };

  let deleteClick = ev => {
    setShowConfirmDelete(true);
  };

  let confirmDelete = async () => {
    setLoading(true);
    setShowConfirmDelete(false);
    for(let item of selected)
      await api.sms.delete(item);
    loadList();

    let res = await api.auth.getUserByToken(true);
    if(res)
      dispatch(fulfillUser(res.data));
  };

  let handleSearch = async ev => {
    setSearch(ev.target.value);
  };

  let filter = (list, search) => {
    if(!search || !search.trim())
      return list;

    let r = getSearchRegExp(search);

    return list.filter(item => r.test(item.code) || !!item.products.find(p => r.test(p.name)));
  };

  let countOnClick = (ev, code) => {
    ev.preventDefault();
    setShowOptInsModal(code);
  };

  async function createOnClick(ev) {
    ev.preventDefault();

    if(await checkLimits.canAddKeyword(user))
      history.push("/sms/edit");
  }

  useEffect(() => {
    loadList();
  }, []);

  return (
    <>
      <h1>
        Keywords
        <Link as="button" to="#" onClick={createOnClick} className="btn btn-plus inline" />
      </h1>

      {loading
        ? (
          <div className="spinner spinner-full"></div>
        )
        : list && list.length
          ? (
            <div className="card p-10 manage-sms-codes-page">
              <div className="card-toolbar">
                <input type="text" className="form-control search" placeholder="Search Keywords" onChange={handleSearch} />
                <div className="d-flex fill justify-content-end">
                  <Link to="#" onClick={createOnClick} className="btn btn-primary font-weight-bolder font-size-sm mr-3">+ New Keyword</Link>
                  <button type="button" className="btn btn-danger font-weight-bolder font-size-sm" disabled={!selected.length} onClick={deleteClick}>Delete</button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                  <thead>
                    <tr>
                      <th width="10"></th>
                      <th>SMS Code</th>
                      <th>Start Date</th>
                      <th>Number of Opt-Ins</th>
                      <th>Shows</th>
                      <th>Status</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filter(list, search).map((item, i) => (
                      <tr key={i}>
                        <td>
                          <label className="checkbox checkbox-lg checkbox-single">
                            <input type="checkbox" onChange={selectedOnChange} value={item._id} checked={selected.includes(item._id)} />
                            <span />
                          </label>
                        </td>
                        <td>
                          <Link to={"sms/" + item._id + "/edit"} className="text-inherit">{item.code}</Link>
                        </td>
                        <td>{moment(item.status == "scheduled" ? item.startDate : item.createdAt).locale("en").format("MMM Do, YYYY")}</td>
                        <td>
                          {item.listenersCount
                            ? <a href="#" onClick={ev => countOnClick(ev, item)}>{item.listenersCount}</a>
                            : "0"}
                        </td>
                        <td>
                          <div className="products-logos">
                            {item.products.map((product, i) => (
                              <ProductLogo product={product} key={i} />
                            ))}
                          </div>
                        </td>
                        <td>
                          <span className={"badge " + statusColors[getEffectiveStatus(item)]}>{statusLabels[getEffectiveStatus(item)]}</span>
                        </td>
                        <td>
                          <Link to={"sms/" + item._id + "/edit"} className="btn btn-secondary font-size-sm">View</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )
          : (
            <div className="manage-sms-codes-page text-center">
              <div className="empty-lottie">
                <Lottie options={{
                  animationData,
                  loop: true,
                  autoplay: true
                }} height={240} width={300} />
              </div>
              <div className="empty-content">
                <p className="mb-10 fs-4">Give access to your show or sales page when people send a keyword to<br />your number or WhatsApp</p>
                <div className="mb-20">
                  <Link to="#" onClick={createOnClick} className="btn btn-primary">Create your first keyword</Link>
                </div>
              </div>
            </div>
          )
      }

      <ConfirmModal
        show={showConfirmDelete}
        message="Are you sure you want to delete the selected codes?"
        onConfirm={confirmDelete}
        onCancel={e => setShowConfirmDelete(false)} />

      <OptInsModal
        show={!!showOptInsModal}
        onHide={() => setShowOptInsModal(false)}
        code={showOptInsModal}
      />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(withRouter(ManageSMSCodes))
);
