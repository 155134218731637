import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import actions from "../../../redux/actions";
import { connect } from "react-redux";
import ProductNavigation from "../../layout/navigation/ProductNavigation";
import { Dropdown, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import api from "../../../redux/api";
import { Tooltip, Switch } from "@material-ui/core";
import UpgradeLink from "../generic/UpgradeLink";
import { getInputClassName, getProductUrl, toastMessage, updateCurrentUser, updateProducts } from "../../helpers";
import { Link } from "react-router-dom";
import moment from "moment";
import CopyButton from "../../layout/components/CopyButton";
import { ProductLogo } from "../../../_metronic/layout/components/productLogo";
import ConfirmModal from "../modals/ConfirmModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserPicture from "../../layout/components/UserPicture";
import { NeedsUpgradeEvents } from "../modals/NeedsUpgrade";
import EmbedFeature from './embed-feature/EmbedFeature'

function StatsValue({ label, stats }) {
    let v = null;
    if(stats && stats.current != 0) {
        if(!stats.lastMonth || stats.current > stats.lastMonth)
            v = "up";
        if(stats.current < stats.lastMonth)
            v = "down";
    }

    let name = {
        up: "Up-2",
        down: "Down-2",
        none: "Minus"
    }[v];

    let color = {
        up: "svg-icon-success",
        down: "svg-icon-danger",
        none: ""
    }[v];

    let arrow = {
        svg: "/media/svg/icons/Navigation/" + name + ".svg",
        color
    };

    return (
        <div className="item">
            <div className="value">
                {!!name && <SVG src={arrow.svg} className={"arrow " + arrow.color} />}
                {parseInt(stats.current).toLocaleString()}
            </div>
            <label>{label}</label>
        </div>
    );
}

function ProductOverview({ product, match, currentUser, dispatch, products }) {
    let [copyTooltip, setCopyTooltip] = useState(false),
        [productLive, setProductLive] = useState(false),
        [salesPageLive, setSalesPageLive] = useState(false),
        [loading, setLoading] = useState(false),
        // [step, setStep] = useState(0),
        [checklistState, setChecklistState] = useState({});

    let loadProduct = async () => {
        let res = await api.product.getProduct(match.params.id);
        dispatch(actions.product.setProduct(res));
    };

    let closeDropdown = () => document.querySelectorAll(".dropdown-menu.show").forEach(elem => elem.classList.remove("show"));

    let copySalesPageUrl = ev => {
        ev.preventDefault();
        closeDropdown();

        try {
            navigator.clipboard.writeText(getProductUrl(product, true));
            setCopyTooltip("URL copied!");
            setTimeout(() => setCopyTooltip(null), 2000);
        } catch(err) { }
    };

    let copyEmbed = ev => {
        ev.preventDefault();
        closeDropdown();

        api.product.editProduct({
            product: {
                _id: product._id,
                embedEnabled: true
            }
        });

        try {
            navigator.clipboard.writeText("<iframe width=\"560\" height=\"315\" src=\"" + process.env.REACT_APP_SERVER_URL + "embed/" + product._id + "\" frameborder=\"0\"></iframe>");
            setCopyTooltip("Embed code copied!");
            setTimeout(() => setCopyTooltip(null), 2000);
        } catch(err) { }
    };

    let copyNumber = ev => {
        ev.preventDefault();
        closeDropdown();

        try {
            let number = currentUser.phoneNumbers.find(n => n.kind == "sms");
            if(!number)
                return toastMessage.error("You don´t have an SMS number.");
            navigator.clipboard.writeText(number);
            setCopyTooltip("Number copied!");
            setTimeout(() => setCopyTooltip(null), 2000);
        } catch(err) { }
    };

    let draftOnClick = async ev => {
        if(product.pendingOwnership)
            return toastMessage.error("You have to verify ownership of the show before publishing it.");

        if(product.overLimit)
            return toastMessage.error("Your account is over limit, upgrade or disable another show publish.");

        setLoading(true);

        await api.product.editProduct({
            product: {
                draft: product.draft ? 0 : 1,
                _id: product._id
            }
        });

        await loadProduct();

        await updateProducts(dispatch);

        setLoading(false);
    };

    let salesPageLiveOnClick = async ev => {
        if(!productLive)
            return toastMessage.error("You can’t enable the sales page while the show is not live.");

        if(!currentUser.planFeatures.salesPage)
            return toastMessage.error("Upgrade to enable sales pages.");

        if(!product.prices.length)
            return toastMessage.error("Complete the pricing to publish.");

        let isFree = !product.prices.find(p => p.type != "free");
        if(!isFree && !currentUser.stripeConnected)
            return toastMessage.error("Connect Stripe to publish.");

        setLoading(true);

        await api.product.editProduct({
            product: {
                salesPageLive: product.salesPageLive ? 0 : 1,
                _id: product._id
            }
        });

        await loadProduct();

        setLoading(false);
    };

    function cardIsHidden(prop) {
        if(!currentUser || !currentUser.onboarding[prop] || !Array.isArray(currentUser.onboarding[prop]))
            return false;

        return currentUser.onboarding[prop].includes(product._id);
    }

    async function hideCard(prop) {
        let data = {};
        data[prop] = Array.isArray(currentUser.onboarding[prop])
            ? [
                ...currentUser.onboarding[prop],
                product._id
            ]
            : [
                product._id
            ];

        await api.user.editCurrentUser({
            action: "updateOnboardingCards",
            user: {},
            data
        });

        await updateCurrentUser(dispatch);
    }

    async function makeLiveOnClick(ev) {
        ev.preventDefault();

        if(product.pendingOwnership || product.overLimit)
            return;

        setLoading(true);

        await api.product.editProduct({
            product: {
                draft: 0,
                _id: product._id
            }
        });

        await loadProduct();

        await updateProducts(dispatch);

        setLoading(false);
    }

    async function updateChecklistStatus() {
        let state = {
            live: "pending",
            addEpisode: "pending",
            episodeLive: "pending",
            automation: "pending"
        };

        if(product) {
            if(productLive) {
                state.live = "done";
            } else if(product.pendingOwnership) {
                state.live = "error";
                state.liveError = "Pending ownership";
            } else if(product.overLimit) {
                state.live = "error";
                state.liveError = "Upgrade to publish";
            } else if(product.systemDisabled) {
                state.live = "error";
                state.liveError = "Not allowed";
            }

            if(product.episodes && product.episodes.length) {
                state.addEpisode = "done";

                if(product.episodes.find(e => e.draft != 1))
                    state.episodeLive = "done";
            }
        }

        if(currentUser) {
            let res = await api.automations.list();

            if(res.data && res.data.find(a => a.products.find(p => p._id == product._id)))
                state.automation = "done";
        }

        setChecklistState(state);
    }

    function scrollToEmbedOnClick(ev) {
        ev.preventDefault();
        let elem = document.querySelector("#embed-card");
        elem.scrollIntoView({
            behavior: "smooth",
            block: "center"
        });
        elem.classList.add("highlight");
    }

    useEffect(() => {
        if(!product || !product.episodes || !currentUser)
            return;

        let live = !product.draft && !product.pendingOwnership && !product.overLimit && !product.systemDisabled;
        setProductLive(live);

        let isFree = product.prices.length > 0 && !product.prices.find(p => p.type != "free");
        setSalesPageLive(live && product.salesPageLive && currentUser.planFeatures.salesPage && (isFree || currentUser.stripeConnected));

        if(product.salesPageLive || currentUser.stripeConnected)
            product.salesPageConfigured = true;

        // if(!product.episodes.length) {
        //     setStep(1);
        // } else if(!product.salesPageConfigured) {
        //     setStep(2);
        // } else if(!product.keywordsCount) {
        //     setStep(3);
        // } else {
        //     setStep(4);
        // }
    }, [product, currentUser]);

    useEffect(() => {
        loadProduct();
    }, []);

    useEffect(() => {
        updateChecklistStatus();
    }, [product, products, currentUser]);

    return (
        <>
            <ProductNavigation product={product} active="overview" />
            {!product || !product.artwork || !product.stats
                ? <div className="loading-block spinner spinner-lg" />
                : (
                    <div className="overview container-inner-lg">
                        <div className="d-flex block-xl">
                            <div className="card details">
                                <div className="d-md-flex align-items-center h-full">
                                    {product.artwork && <img src={product.artwork.thumbnail} className="product-image mb-8 mb-md-0" />}
                                    <div className="fill">
                                        <div className="buttons">
                                            <Tooltip PopperProps={{ disablePortal: true }} onClose={() => setCopyTooltip(null)} open={copyTooltip} disableFocusListener disableHoverListener arrow placement="top" title={copyTooltip}>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="btn btn-lg btn-secondary mr-4 fs-inherit">
                                                        Share
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <a href="#" onClick={copySalesPageUrl} className="dropdown-item">
                                                            <div className="icon">
                                                                <SVG src={toAbsoluteUrl("/media/def-image/icons/link.svg")} />
                                                            </div>
                                                            Copy sales page URL
                                                        </a>
                                                        <a href="#" onClick={copyEmbed} className="dropdown-item">
                                                            <div className="icon">
                                                                <SVG src={toAbsoluteUrl("/media/def-image/icons/code.svg")} />
                                                            </div>
                                                            Copy embed
                                                        </a>
                                                        <a href="#" onClick={copyNumber} className="dropdown-item">
                                                            <div className="icon">
                                                                <SVG src={toAbsoluteUrl("/media/def-image/icons/phone.svg")} />
                                                            </div>
                                                            Copy keyword number
                                                        </a>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Tooltip>
                                            {(product.host || product.publicUri) && (
                                                <a href={productLive && salesPageLive
                                                    ? getProductUrl(product)
                                                    : process.env.REACT_APP_SERVER_URL + "api/sales-page-preview/" + product._id} className="btn btn-secondary btn-lg fs-inherit" target="_blank">
                                                    {productLive && salesPageLive ? "Sales Page" : "Preview Sales Page"}
                                                </a>
                                            )}
                                        </div>
                                        <div className="stats">
                                            {product.stats && product.stats.downloads && product.stats.listenersCount && (
                                                <>
                                                    <StatsValue label="Downloads" stats={product.stats.downloads} />
                                                    <StatsValue label="Listeners" stats={product.stats.listenersCount} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card status">
                                <div className="d-flex border-bottom-dashed align-items-center">
                                    <div className="icon">
                                        <SVG src={toAbsoluteUrl("/media/def-image/icons/show-color.svg")} className={productLive ? "active" : ""} />
                                    </div>
                                    <div className="fill">
                                        {!productLive
                                            ? (
                                                <>
                                                    <strong>Show not live</strong><br />
                                                    <div className="text-muted">
                                                        {product.systemDisabled
                                                            ? "Upgrade to enable this show"
                                                            : "Your show is not available to listeners"}
                                                    </div>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <strong className="text-primary">Show is live</strong><br />
                                                    <div className="text-muted">Your show is now available to listeners</div>
                                                </>
                                            )}
                                        {product.overLimit && <UpgradeLink label="Upgrade for more audio feeds" user={currentUser} className="mt-2" />}
                                    </div>
                                    <div className="switch">
                                        <Switch disabled={loading} onClick={draftOnClick} checked={!!productLive} className={"blue-switch" + (loading ? " disabled" : "")} />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="icon">
                                        <SVG src={salesPageLive
                                            ? "/media/def-image/icons/sales-page-live.svg"
                                            : "/media/def-image/icons/sales-page-color.svg"} className={salesPageLive ? "active" : ""} />
                                    </div>
                                    <div className="fill">
                                        {!salesPageLive
                                            ? (
                                                <>
                                                    <strong>Sales page is draft</strong><br />
                                                    <div className="text-muted">
                                                        {!productLive
                                                            ? "Publish your product to enable the sales page"
                                                            : (
                                                                !currentUser.planFeatures.salesPage
                                                                    ? "Upgrade to enable the sales page"
                                                                    : "Your sales page is not available to customers"
                                                            )}
                                                    </div>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <strong className="text-primary">Sales page is live</strong><br />
                                                    <div className="text-muted">Your sales page is now available to customers</div>
                                                </>
                                            )}
                                        {productLive && !currentUser.planFeatures.salesPage && <UpgradeLink user={currentUser} className="mt-2" />}
                                    </div>
                                    <div className="switch">
                                        <Switch disabled={loading} onClick={salesPageLiveOnClick} checked={!!salesPageLive} value="1" name="salesPageLive" className={"blue-switch" + (loading ? " disabled" : "")} />
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="row wide-gap">
                            {!cardIsHidden("hideProductChecklistCard") && (
                                <div className="col-lg-6">
                                    <div className="card getting-started-card mb">
                                        <div>
                                            <button type="button" className="btn hide-card" onClick={() => hideCard("hideProductChecklistCard")}>
                                                <SVG src="/media/def-image/close.svg" />
                                            </button>

                                            <label className="form-label has-icon">
                                                <SVG src="/media/def-image/icons/show-checklist.svg" className="icon" />
                                                <span className="label-text">
                                                    <h2 className="mb-2">Go-live checklist</h2>
                                                    <p className="mb-8">Complete these steps before adding listeners.</p>
                                                </span>
                                            </label>


                                            <div className="checklist">
                                                <div className={"checklist-item state-" + checklistState.live}>
                                                    <span className="number">1</span>
                                                    <span className="check">
                                                        <SVG src="/media/def-image/icons/check-2.svg" />
                                                    </span>
                                                    <div className="text">
                                                        Set show status to live
                                                        {checklistState.live == "error" && <span className="badge yellow-badge ml-4">{checklistState.liveError}</span>}
                                                    </div>
                                                    <div className="options">
                                                        {loading
                                                            ? (
                                                                <span className="loading spinner spinner-sm mr-10" />
                                                            )
                                                            : (
                                                                <>
                                                                    {checklistState.live == "done" && <span className="text-muted">Completed</span>}
                                                                    {checklistState.live == "pending" && <a href="#" onClick={makeLiveOnClick}><SVG src="/media/def-image/icons/edit-blue.svg" /></a>}
                                                                </>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className={"checklist-item state-" + checklistState.addEpisode}>
                                                    <span className="number">2</span>
                                                    <span className="check">
                                                        <SVG src="/media/def-image/icons/check-2.svg" />
                                                    </span>
                                                    <div className="text">
                                                        Add at least one episode
                                                    </div>
                                                    <div className="options">
                                                        {checklistState.addEpisode == "done"
                                                            ? <span className="text-muted">Completed</span>
                                                            : <Link to={"/products/" + product._id + "/episodes"}><SVG src="/media/def-image/icons/edit-blue.svg" /></Link>}
                                                    </div>
                                                </div>

                                                <div className={"checklist-item state-" + checklistState.episodeLive}>
                                                    <span className="number">3</span>
                                                    <span className="check">
                                                        <SVG src="/media/def-image/icons/check-2.svg" />
                                                    </span>
                                                    <div className="text">
                                                        Set at least one episode status to live
                                                    </div>
                                                    <div className="options">
                                                        {checklistState.episodeLive == "done"
                                                            ? <span className="text-muted">Completed</span>
                                                            : <Link to={"/products/" + product._id + "/episodes"}><SVG src="/media/def-image/icons/edit-blue.svg" /></Link>}
                                                    </div>
                                                </div>

                                                <div className={"checklist-item state-" + checklistState.automation}>
                                                    <span className="number">4</span>
                                                    <span className="check">
                                                        <SVG src="/media/def-image/icons/check-2.svg" />
                                                    </span>
                                                    <div className="text">
                                                        Make show interactive by automating events <small>(Optional)</small>
                                                    </div>
                                                    <div className="options">
                                                        {checklistState.automation == "done"
                                                            ? <span className="text-muted">Completed</span>
                                                            : <Link to="/automations"><SVG src="/media/def-image/icons/edit-blue.svg" /></Link>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="bottom-link-container">
                                                <a href="https://hiro.fm/kbtopic/creating-shows/" target="_blank" class="bottom-link">View step-by-step guides</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!cardIsHidden("hideProductPromoteCard") && (
                                <div className="col-lg-6">
                                    <div className="card getting-started-card mb">
                                        <div>
                                            <button type="button" className="btn hide-card" onClick={() => hideCard("hideProductPromoteCard")}>
                                                <SVG src="/media/def-image/close.svg" />
                                            </button>

                                            <label className="form-label has-icon">
                                                <SVG src="/media/def-image/icons/add-listeners.svg" className="icon" />
                                                <span className="label-text">
                                                    <h2 className="mb-2">Promote & share your show</h2>
                                                    <p className="mb-8">Use these methods to give listeners access to your show.</p>
                                                </span>
                                            </label>


                                            <div className="checklist">
                                                <div className="checklist-item state-pending">
                                                    <span className="number">1</span>

                                                    <div className="text">
                                                        Hiro sales page
                                                        {checklistState.live == "error" && <span className="badge yellow-badge ml-4">{checklistState.liveError}</span>}
                                                    </div>
                                                    <div className="options">
                                                        <Link to={"/products/" + product._id + "/sales-page"}>
                                                            <SVG src="/media/def-image/icons/edit-blue.svg" />
                                                        </Link>
                                                        <a href="https://hiro.fm/kb/how-set-up-the-no-code-sales-page/" target="_blank"><SVG src="/media/def-image/icons/question-2.svg" /></a>
                                                    </div>
                                                </div>

                                                <div className="checklist-item state-pending">
                                                    <span className="number">2</span>
                                                    <span className="check">
                                                        <SVG src="/media/def-image/icons/check-2.svg" />
                                                    </span>
                                                    <div className="text">
                                                        Keyword opt-in
                                                    </div>
                                                    <div className="options">
                                                        <Link to="/sms">
                                                            <SVG src="/media/def-image/icons/edit-blue.svg" />
                                                        </Link>
                                                        <a href="https://hiro.fm/kb/how-to-set-up-sms-keyword-opt-in/" target="_blank"><SVG src="/media/def-image/icons/question-2.svg" /></a>
                                                    </div>
                                                </div>

                                                <div className="checklist-item state-pending">
                                                    <span className="number">3</span>
                                                    <span className="check">
                                                        <SVG src="/media/def-image/icons/check-2.svg" />
                                                    </span>
                                                    <div className="text">
                                                        Use your website
                                                    </div>
                                                    <div className="options">
                                                        <Dropdown drop="down" className="dropdown-inline no-chevron">
                                                            <Dropdown.Toggle className="btn-transparent">
                                                                <SVG src="/media/def-image/icons/edit-blue.svg" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu popperConfig={{}} renderOnMount>
                                                                <a className="dropdown-item no-plan-cant-click" href="https://zapier.com/apps/hirofm/integrations" target="_blank">
                                                                    <div className="icon">
                                                                        <SVG src="/media/def-image/icons/zapier.svg" />
                                                                    </div>
                                                                    Zapier
                                                                </a>
                                                                <a className="dropdown-item no-plan-cant-click" href="https://www.pabbly.com/connect/integrations/hiro-fm/" target="_blank">
                                                                    <div className="icon">
                                                                        <SVG src="/media/def-image/icons/pabbly.svg" />
                                                                    </div>
                                                                    Pabbly
                                                                </a>
                                                                <a className="dropdown-item no-plan-cant-click" href="https://marketplace.gohighlevel.com/integration/65c3ca4663331e6b1c8f98ae" target="_blank">
                                                                    <div className="icon">
                                                                        <SVG src="/media/def-image/icons/ghl.svg" />
                                                                    </div>
                                                                    HighLevel
                                                                </a>
                                                                <a className="dropdown-item no-plan-cant-click" href="https://hirofm.docs.apiary.io/#reference/0/contacts-and-listeners" target="_blank">
                                                                    <div className="icon">
                                                                        <SVG src="/media/def-image/icons/api.svg" />
                                                                    </div>
                                                                    Public API
                                                                </a>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <a href="https://hiro.fm/kbtopic/integrations/" target="_blank"> <SVG src="/media/def-image/icons/question-2.svg" /></a>
                                                    </div>
                                                </div>

                                                <div className="checklist-item state-pending">
                                                    <span className="number">4</span>
                                                    <span className="check">
                                                        <SVG src="/media/def-image/icons/check-2.svg" />
                                                    </span>
                                                    <div className="text">
                                                        Embed sales page
                                                    </div>
                                                    <div className="options">
                                                        <a href="#" onClick={scrollToEmbedOnClick}>
                                                            <SVG src="/media/def-image/icons/edit-blue.svg" />
                                                        </a>
                                                        <a href="https://hiro.fm/kb/how-to-embed-your-sales-page-on-other-websites/" target="_blank"> <SVG src="/media/def-image/icons/question-2.svg" /></a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="bottom-link-container">
                                                <a href="https://hiro.fm/kb/how-to-give-listeners-access-to-your-show/" target="_blank" class="bottom-link">View step-by-step guides</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <EmbedFeature productId={product._id} productLive={productLive} publicUri={product.publicUri} />




                        {/* <div className="card next">
                            <div>
                                <h2 className="mb-10">Here’s what happens next.</h2>
                                <div className="roadmap">
                                    <div className="step completed">
                                        <div className="icon">
                                            <SVG src={toAbsoluteUrl("/media/def-image/icons/check.svg")} />
                                        </div>
                                        <div className="content">
                                            Your show is set up
                                        </div>
                                    </div>
                                    {step <= 1
                                        ? (
                                            <div className="step current">
                                                <div className="icon">
                                                    <SVG src={toAbsoluteUrl("/media/def-image/icons/upload.svg")} />
                                                </div>
                                                <div className="content">
                                                    <h2>Upload your first episode</h2>
                                                    <p>Tip: if you’re not totally ready to commit, try making a short welcome eipsode to get your show out there.</p>
                                                    <Link to={"/products/" + product._id + "/episodes/create-single"} className="btn btn-primary-light btn-lg fs-inherit">Let’s do it!</Link>
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <div className="step completed">
                                                <div className="icon">
                                                    <SVG src={toAbsoluteUrl("/media/def-image/icons/check.svg")} />
                                                </div>
                                                <div className="content">
                                                    First episode is published
                                                </div>
                                            </div>
                                        )}
                                    {step <= 2
                                        ? (
                                            <div className={"step " + (step == 2 ? "current" : "")}>
                                                <div className="icon">
                                                    <SVG src={toAbsoluteUrl("/media/def-image/icons/dollar.svg")} />
                                                </div>
                                                <div className="content">
                                                    <h2>Set up monetization <small>(optional)</small></h2>
                                                    <p>Set up your sales page. You can also give free sample episodes and enable listeners to unlock the full show.</p>
                                                    <Link to={"/products/" + product._id + "/sales-page"} className={"btn btn-lg fs-inherit " + (step == 2 ? "btn-primary-light" : "btn-secondary")}>Go to monentization setup</Link>
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <div className={"step completed"}>
                                                <div className="icon">
                                                    <SVG src={toAbsoluteUrl("/media/def-image/icons/check.svg")} />
                                                </div>
                                                <div className="content">
                                                    Sales page is set up
                                                </div>
                                            </div>
                                        )}
                                    {step <= 3
                                        ? (
                                            <div className={"step " + (step == 3 ? "current" : "")}>
                                                <div className="icon">
                                                    <SVG src={toAbsoluteUrl("/media/def-image/icons/stats-2.svg")} />
                                                </div>
                                                <div className="content">
                                                    <h2>Get Listeners!</h2>
                                                    <p>Now all there is to do is share your keyword and get listeners to opt-in. Or you can share you sales page.</p>
                                                    <Link to={"/sms#create"} className={"btn btn-lg fs-inherit " + (step == 3 ? "btn-primary-light" : "btn-secondary")}>Create your Keyword</Link>
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <div className={"step completed"}>
                                                <div className="icon">
                                                    <SVG src={toAbsoluteUrl("/media/def-image/icons/check.svg")} />
                                                </div>
                                                <div className="content">
                                                    Keyword is live
                                                </div>
                                            </div>
                                        )}
                                </div>
                                {step == 4 && (
                                    <div className="text-center mt-10">
                                        <a href="https://hiro.fm/how-to-get-traffic-for-your-audiobook-or-private-podcast/" target="_blank" className="btn btn-lg fs-inherit btn-secondary">Tips to getting listeners</a>
                                    </div>
                                )}
                            </div>
                        </div> */}

                        {!product.preventCopy && <ShareCodes user={currentUser} product={product} products={products} dispatch={dispatch} />}
                    </div>
                )}
        </>
    );
}

function ShareCodes({ user, product, dispatch, products }) {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Enter the name."),
        code: Yup.string().required("Enter the code."),
        //productId: Yup.string().required("Select the show.")
    });

    const initialValues = {
        name: "",
        code: "",
        preventCopy: false
        //productId: ""
    };

    const [confirmDelete, setConfirmDelete] = useState(),
        [deleting, setDeleting] = useState(),
        [showEditModal, setShowEditModal] = useState(false),
        [saving, setSaving] = useState(false),
        [showDetails, setShowDetails] = useState(false),
        [details, setDetails] = useState(),
        [list, setList] = useState([]),
        [editCode, setEditCode] = useState(),
        formik = useFormik({
            initialValues,
            validationSchema,
            onSubmit: values => save(values)
        });

    function editOnClick(item) {
        setEditCode(item);
        formik.setValues({
            name: item.name,
            code: item.code,
            preventCopy: item.preventCopy
            //productId: item.productId
        }, true);
        formik.setTouched({});
        setShowEditModal(true);
    }

    async function timesUsedOnClick(ev, item) {
        ev.preventDefault();

        setDetails(null);
        setShowDetails(true);

        let res = await api.user.getShareCodeDetails(item.code);

        if(!res || !res.success) {
            setShowDetails(false);
            return toastMessage.error((res && res.error) || "Unable to connect to the server.");
        }

        distinctUsage(res.data);

        setDetails(res.data);
    }

    function createOnClick() {
        if((user.owner && !user.owner.planFeatures.shareShows) || (!user.owner && !user.planFeatures.shareShows))
            return NeedsUpgradeEvents.dispatchShow("Upgrade to access this feature");
        setEditCode(null);
        formik.resetForm();
        setShowEditModal(true);
    }

    function distinctUsage(code) {
        let ids = [],
            result = [];
        for(let item of code.usage) {
            if(!ids.includes(item.instructorId)) {
                result.push(item);
                ids.push(item.instructorId);
            }
        }
        code.usage = result;
        return code;
    }

    async function save(values) {
        setSaving(true);

        values.productId = product._id;

        let res = !!editCode
            ? await api.user.updateShareCode(editCode.code, values)
            : await api.user.createShareCode(values);

        if(res && res.success)
            await updateCurrentUser(dispatch);

        setSaving(false);

        if(!res || !res.success)
            return toastMessage.error((res && res.error) || "Unable to connect to the server");

        setShowEditModal(false);
    }

    async function deleteOnConfirm() {
        setDeleting(true);

        let res = await api.user.deleteShareCode(confirmDelete.code);

        if(res && res.success)
            await updateCurrentUser(dispatch);

        setDeleting(false);

        if(!res || !res.success)
            return toastMessage.error((res && res.error) || "Unable to connect to the server");

        setConfirmDelete(null);
    }

    useEffect(() => {
        let shareCodes = [];
        if(user && product && Array.isArray(user.shareCodes)) {
            shareCodes = user.shareCodes
                .filter(c => c.productId == product._id)
                .map(c => distinctUsage(c));
        }
        setList(shareCodes);
    }, [user, product]);

    return (
        <>
            <div className="card">
                <div>
                    <h2 className="mb-10">Share with other Hiro members</h2>
                    <h3 className="fs-6 mb-1">Share Codes</h3>
                    <p className="mb-10">Allow other Hiro members to import your show, episodes and automations by generating a share code below. Anyone with a copy of your code will be able to add all of your show content, so please share your codes with caution.</p>

                    {!!list.length && (
                        <div className="table-responsive mb-10">
                            <table className="table table-head-custom table-vertical-center">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Show</th>
                                        <th>Code</th>
                                        <th>Created At</th>
                                        <th>Last used</th>
                                        <th>Times used</th>
                                        <th width="10"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map(item => (
                                        <tr key={item.code}>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                <div className="products-logos">
                                                    <ProductLogo product={item.productId} products={products} />
                                                </div>
                                            </td>
                                            <td>
                                                {item.code}
                                                <CopyButton successTooltipText="Copied!" text={item.code} className="h-auto ml-4" />
                                            </td>
                                            <td>
                                                {moment(item.createdAt).locale("en").format("MMM D, YYYY")}
                                            </td>
                                            <td>
                                                {item.lastUsedAt
                                                    ? moment(item.lastUsedAt).locale("en").format("MMM D, YYYY")
                                                    : "Never"}
                                            </td>
                                            <td>
                                                {item.usage.length > 0
                                                    ? <a href="#" onClick={ev => timesUsedOnClick(ev, item)}>{item.usage.length}</a>
                                                    : item.usage.length}
                                            </td>
                                            <td className="pr-0 text-right text-nowrap">
                                                <button type="button" className="btn btn-transparent mr-4" title="Edit" onClick={() => editOnClick(item)}>
                                                    <SVG src="/media/def-image/icons/pencil.svg" />
                                                </button>
                                                <button type="button" className="btn btn-transparent" title="Remove" onClick={() => setConfirmDelete(item)}>
                                                    <SVG src="/media/def-image/icons/delete.svg" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <button type="button" className="btn btn-primary" onClick={createOnClick}>Generate a new code</button>
                </div>
            </div>

            <ConfirmModal show={!!confirmDelete} onHide={() => setConfirmDelete(null)} message="Are you sure you want to delete the code? Users who already used the code will keep their shows." onCancel={() => setConfirmDelete(null)} onConfirm={deleteOnConfirm} loading={deleting} />

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="md" centered className="new-modals edit-share-code-modal">
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header>
                        <h1 className="m-0">
                            {!!editCode
                                ? "Edit code"
                                : "New code"}
                        </h1>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group mb-5">
                            <label className="form-label">Name or description:</label>
                            <input type="text" autoFocus className={getInputClassName(formik, "name")} {...formik.getFieldProps("name")} />
                            {formik.touched.name && formik.errors.name && <div className="field-error">{formik.errors.name}</div>}
                        </div>

                        <div className="form-group mb-8">
                            <label className="form-label">Code:</label>
                            <input type="text" className={getInputClassName(formik, "code")} {...formik.getFieldProps("code")} />
                            {formik.touched.code && formik.errors.code && <div className="field-error">{formik.errors.code}</div>}
                        </div>

                        <div className="form-group">
                            <label className="checkbox checkbox-lg">
                                <input type="checkbox" onChange={ev => formik.setFieldValue("preventCopy", ev.target.checked)} checked={formik.values.preventCopy} />
                                <span />
                                Prevent users from generating a copy code
                            </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setShowEditModal(false)}>Cancel</button>
                        <button type="submit" className={"btn btn-primary " + (saving ? "loading spinner" : "")}>Save</button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={showDetails} onHide={() => setShowDetails(false)} size="md" centered className="new-modals share-code-details-modal">
                <Modal.Body>
                    <button type="button" className="close" onClick={() => setShowDetails(false)}>
                        <SVG src="/media/def-image/close-sm.svg" />
                    </button>
                    {!!details
                        ? (
                            <>
                                <h2>Who used your code “{details.name}”</h2>

                                <div className="table-responsive">
                                    <table className="table table-head-custom table-vertical-center">
                                        <thead>
                                            <tr>
                                                <th colSpan="2">User name</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {details.usage.map(item => (
                                                <tr key={item.instructorId}>
                                                    <td width="1">
                                                        <UserPicture user={item} />
                                                    </td>
                                                    <td>
                                                        {item.firstName} {item.lastName}
                                                    </td>
                                                    <td>
                                                        {moment(item.date).locale("en").format("MMM D, YYYY")}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )
                        : (
                            <div className="loading-block spinner spinner-lg" />
                        )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default injectIntl(connect(
    store => ({
        products: store.product.products,
        currentUser: store.auth.user,
        product: (store.product || []).product || {},
    }),
    dispatch => ({
        ...actions.product,
        dispatch,
    })
)(ProductOverview));